window.addEventListener('load', function () {
  initialize_navigation();
  set_active_menu_item();
  initialize_observers();
  initialize_actions();
  initialize_validations();
});

function initialize_navigation() {
  var hamburger = document.getElementById("hamburger");
  if (hamburger == null) {
    return;
  }

  var menu = document.getElementById("main-menu");

  hamburger.addEventListener("click", function () {
    this.classList.toggle("active");
    menu.classList.toggle("active");
  });

  menu.addEventListener("click", function () {
    this.classList.remove("active");
    hamburger.classList.remove("active");
  });

  window.addEventListener("scroll", function () {
    if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
      document.getElementById("header").classList.add("shrinked");
    } else {
      document.getElementById("header").classList.remove("shrinked");
    }
  });
}

function set_active_menu_item() {
  if (!String.prototype.endsWith) {
    String.prototype.endsWith = function (suffix) {
      return this.indexOf(suffix, this.length - suffix.length) !== -1;
    };
  }

  const targets = document.getElementsByClassName('nav-link');
  for (var idx = 0; idx < targets.length; idx++) {
    if (targets[idx].href.endsWith(window.location.pathname)) {
      targets[idx].parentElement.classList.add('is-active');
      if (targets[idx].classList.contains('sub')) {
        targets[idx].parentElement.parentElement.parentElement.classList.add('is-active');
      }
      return;
    }
  }
}

function initialize_observers() {
  if ('IntersectionObserver' in window) {

    const borderWhenInView = new IntersectionObserver((entries) => {
      for (var j = 0; j < entries.length; j++) {
        if (entries[j].intersectionRatio > 0) {
          entries[j].target.classList.add('in-view');
        }
      }
    });

    shootoutElements = document.getElementsByClassName("section-shootout");
    for (var i = 0; i < shootoutElements.length; i++) {
      borderWhenInView.observe(shootoutElements[i]);
    }

    const facebookPostsInView = new IntersectionObserver((entries) => {
      for (var j = 0; j < entries.length; j++) {
        if (entries[j].intersectionRatio > 0) {
          if (entries[j].target.attributes["data-src"] != undefined) {
            if (!entries[j].target.classList.contains('resolved-loader-wrapper')) {
              entries[j].target.innerHTML = entries[j].target.attributes["data-src"].value;
              entries[j].target.classList.add('resolved-loader-wrapper');
            }
          }
        }
      }
    });

    facebookRecommendations = document.getElementsByClassName("loader-wrapper");
    for (var k = 0; k < facebookRecommendations.length; k++) {
      facebookPostsInView.observe(facebookRecommendations[k]);
    }
  } else {
    shootoutElements = document.getElementsByClassName("section-shootout");
    for (var i = 0; i < shootoutElements.length; i++) {
      shootoutElements[i].classList.add('in-view');
    }

    facebookRecommendations = document.getElementsByClassName("loader-wrapper");
    for (var k = 0; k < facebookRecommendations.length; k++) {
      if (facebookRecommendations[k].attributes["data-src"] != undefined) {
        if (!facebookRecommendations[k].classList.contains('resolved-loader-wrapper')) {
          facebookRecommendations[k].innerHTML = facebookRecommendations[k].attributes["data-src"].value;
          facebookRecommendations[k].classList.add('resolved-loader-wrapper');
        }
      }
    }
  }
}

function initialize_actions() {

  // Landing page
  if (!String.prototype.startsWith) {
    String.prototype.startsWith = function (prefix) {
      return this.indexOf(prefix) === 0;
    };
  }
  if (window.location.pathname.startsWith('/price_lists')) {

    var displayContactPopupButtons = document.getElementsByClassName('display-contact-popup');
    for (var i = 0; i < displayContactPopupButtons.length; i++) {
      displayContactPopupButtons.item(i).addEventListener("click", function () {
        var popup = document.getElementById("modal-box");
        popup.style.display = 'flex';
      });
    }

    var returnToClientButtons = document.getElementsByClassName('return-to-client');
    for (var i = 0; i < returnToClientButtons.length; i++) {
      returnToClientButtons.item(i).addEventListener("click", function () {

        var http = new XMLHttpRequest();
        var url = '/operations/return_to_client';
        var params = window.location.search.substring(1);
        http.open('POST', url, true);

        //Send the proper header information along with the request
        http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        http.setRequestHeader('X-CSRF-Token', document.querySelector('meta[name="csrf-token"]').content);

        http.onreadystatechange = function () {//Call a function when the state changes.
          if (http.readyState == 4) {
            if (http.status != 200) {
              var errorMessage = document.getElementById("model-box-message");
              errorMessage.innerHTML = 'אירעה שגיאה בעת שליחת הבקשה,<br>' +
                'אנא צור קשר עם משרד מותר לי בטלפון ' +
                "<a href='tel:0558817052'>0558817052</a>";
            }

            var popup = document.getElementById("modal-box");
            popup.style.display = 'flex';
          }
        }

        http.send(params);
      });
    }

    var modalBoxCloseButton = document.getElementById("modal-box-close-button");
    if (modalBoxCloseButton != null) {
      modalBoxCloseButton.addEventListener("click", function () {
        var popup = document.getElementById("modal-box");
        popup.style.display = 'none';
      });
    }
  }

}

const oninputFunction = (field) => () => field.setCustomValidity('')
const oninvalidFunction = (field) => () => {
  field.setCustomValidity(field.getAttribute('data-validation'))
}

function initialize_validations() {
  fieldsToValidate = document.querySelectorAll('input[data-validation]')
  fieldsToValidate.forEach(field => {
    field.addEventListener("invalid", oninvalidFunction(field));
    field.addEventListener("input", oninputFunction(field));
  })
}